<template>
  <div class="share-social">
    <div class="share-icons">
      <button
        v-if="
          $route.params.slug !== 'msutunnel' &&
          $route.params.slug !== '2021buickkaraoke' &&
          $route.params.slug !== '2021buickkaraokevolleyball'
        "
        class="social-icon icon-email"
        @click="$emit('shareEmail', 'shareEmail')"
      ></button>
      <button v-else class="social-icon icon-sms" @click="$emit('shareSMS', 'shareSMS')"></button>

      <button
        v-if="
          $route.params.slug !== 'msutunnel' &&
          $route.params.slug !== '2021buickkaraoke' &&
          $route.params.slug !== '2021buickkaraokevolleyball'
        "
        class="social-icon icon-sms"
        @click="$emit('shareSMS', 'shareSMS')"
      ></button>
      <button v-else class="social-icon icon-email" @click="$emit('shareEmail', 'shareEmail')"></button>
      <button
        class="social-icon icon-facebook"
        @click="facebookLink"
        v-if="!isKiosk && $route.params.slug !== 'jordan24'"
      ></button>
      <button
        class="social-icon icon-twitter"
        @click="twitterLink"
        v-if="!isKiosk && $route.params.slug !== 'jordan24'"
      ></button>
      <button
        class="social-icon icon-download"
        @click="share"
        v-if="!isKiosk && $route.params.slug === 'jordan24'"
      ></button>
      <button
        class="social-icon icon-instagram"
        @click="$emit('shareInsta', 'shareInsta')"
        v-if="
          !isKiosk &&
          $route.params.slug !== 'jackierobinson' &&
          $route.params.slug !== 'chevytc2022det' &&
          $route.params.slug !== 'chevycomicbook22det'
        "
      ></button>
      <button
        class="social-icon icon-linkedin"
        @click="shareOnLinkedin"
        v-if="
          !isKiosk &&
          ($route.params.slug === 'essilortransitions' ||
            $route.params.slug === 'brightdropphotoop' ||
            $route.params.slug === 'flywithakamai' ||
            $route.params.slug === 'biogen2023')
        "
      ></button>
      <button
        class="social-icon icon-tiktok"
        @click="$emit('shareTiktok', 'shareTiktok')"
        v-if="($route.params.slug === 'chevycma2022' || $route.params.slug === 'chevymlballstar2022') && !isKiosk"
      ></button>
      <!-- @tiktokDownload="tiktokDownload" -->
      <a
        :href="s3Link"
        v-if="
          $route.params.slug === 'essilorlenswithfriends' ||
          $route.params.slug === 'essilorvogue' ||
          $route.params.slug === 'essiloroakley' ||
          $route.params.slug === 'essilortransitions' ||
          $route.params.slug === 'essilorrayban' ||
          $route.params.slug === 'toyota23'
        "
        download
        class="social-icon icon-download"
        @click="$emit('shareDownload', 'shareDownload')"
      >
      </a>
      <div class="qr-code" v-if="isKiosk">
        <VueQRcode :scale="8" :maskPattern="4" :width="12" :value="shareURL" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import VueQRcode from 'vue-qrcode';

export default {
  name: 'ShareSocial',
  props: {},
  components: {
    VueQRcode,
  },
  head() {
    return {
      meta: [
        { property: 'og:title', content: 'This is a test' },
        { property: 'og:image', content: this.s3Link },
        { property: 'twitter:card', content: 'summary_large_image' },
      ],
    };
  },
  async created() {
    const URL = process.env.VUE_APP_MODE === 'kiosk' ? '/' : process.env.VUE_APP_ONLINE_URL;

    const res = await axios.get(`${URL}/assets/${this.assetID}`);
    this.s3Link = res.data.data.s3Link;
  },
  data() {
    return {
      s3Link: null,
      assetID: this.$route.params.assetID,
      shareType: '',
    };
  },
  computed: {
    shareURL() {
      return `${process.env.VUE_APP_FE_APP}/${this.$route.params.slug}/${this.assetID}`;
    },
    isKiosk() {
      return process.env.VUE_APP_MODE === 'kiosk';
    },
  },
  methods: {
    // tiktokDownload() {
    //   axios({ url: this.s3link, method: 'GET', responseType: 'blob' }).then((response) => {
    //     const url = window.URL.createObjectURL(new Blob([response.data]));
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', `${this.$route.params.slug}.mp4`);
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //   });
    // },
    facebookLink() {
      window.location.href = `${process.env.VUE_APP_ONLINE_URL}/facebook/login?assetId=${this.assetID}&s3Link=${this.s3Link}`;
    },

    twitterLink() {
      window.location.href = `${process.env.VUE_APP_ONLINE_URL}/twitter/login?assetId=${this.assetID}&s3Link=${this.s3Link}`;
    },

    tiktokLink() {
      window.location.href = `${process.env.VUE_APP_ONLINE_URL}/tiktok/login?assetId=${this.assetID}`;
    },

    shareOnLinkedin() {
      window.location.href = `https://www.linkedin.com/shareArticle?mini=true&url=${this.shareURL}`;
    },

    instaLink() {
      this.instagram = true;
      this.shareType = 'email';
      this.showModal = true;
    },

    download(blob) {
      // use the s3link to trigger download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${this.$route.params.assetID}.mp4`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async share() {
      const blob = await axios.get(this.s3Link, { responseType: 'blob' });
      const file = new File([blob.data], `${this.$route.params.assetID}.mp4`, { type: 'video/mp4' });
      const shareData = {
        files: [file],
      };
      if (!navigator.canShare || !navigator.canShare(shareData)) {
        this.download(blob);
      } else {
        navigator.share(shareData);
      }
    },
    emailLink() {
      this.instagram = false;
      this.shareType = 'email';
      this.showModal = true;
    },
    smsLink() {
      this.shareType = 'sms';
      this.showModal = true;
    },
    showThanks() {
      this.showThankYou = true;
      clearTimeout(this.thankYouTimer);
      setTimeout(() => {
        this.showThankYou = false;
      }, 5000);
    },
  },
};
</script>

<style lang="scss" scoped>
.share-social {
  width: 100%;
  max-height: 145px;
  margin-top: 45px;
  display: flex;
  flex-wrap: wrap;

  .share-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    flex-grow: 1;

    .social-icon {
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      width: 60px;
      height: 60px;
      border: none;
      outline: none;

      @include md-tablet {
        margin-right: 15px;
        width: 65px;
        height: 65px;
      }
    }

    @include md-tablet {
      justify-content: flex-start;
    }
  }
}

.qr-code {
  // margin: 0 auto 0 0;
  width: 120px;
  height: 120px;
  & > * {
    border-radius: 10px;
    width: 100%;
    height: auto;
  }
}
</style>
